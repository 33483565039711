export class Constants {
  public static readonly DEFAULT_TABLE_CONFIG_NAME = 'Wheel Screener Default'
  public static readonly OPTIONS_PER_PAGE_API = 100
  public static readonly ANIMATION_DURATION = 1000
  public static readonly ANIMATION_DELAY = 600
  public static readonly LEFT_PAGE = 'LEFT'
  public static readonly RIGHT_PAGE = 'RIGHT'
  public static readonly PAGINATOR_PAGE_NEIGHBORS = 2
  public static readonly LOG_OUT_TOAST_WINDOW_ID = 'LOG_OUT_TOAST_WINDOW_ID'
  public static readonly LOG_OUT_TOAST_MESSAGE_ID = 'LOG_OUT_TOAST_MESSAGE_ID'
  public static readonly DOWNLOAD_START_TOAST_ID = 'DOWNLOAD_START_TOAST_ID'
  public static readonly DOWNLOAD_FINISHED_TOAST_ID = 'DOWNLOAD_FINISHED_TOAST_ID'
  public static readonly STAGING_WARNING_TOAST_ID = 'STAGING_WARNING_TOAST_ID'
  public static readonly RETRIEVING_CONTRACTS_ERROR_TOAST_ID = 'RETRIEVING_CONTRACTS_ERROR_TOAST_ID'
  public static readonly FAILED_TO_FETCH_TOAST_ID = 'RETRIEVING_CONTRACTS_ERROR_TOAST_ID'
  public static readonly FORMS_MESSAGE_MIN_LENGTH = 50
  public static readonly FORMS_MESSAGE_MAX_LENGTH = 500
  public static readonly INDEXED_DB_SCHEMA_VERSION = 4
  public static readonly PRICE_HUB_URL = '/priceHub'
  public static readonly PRICES_UPDATED_EVENT_ID = 'PricesUpdated'
  public static readonly MAX_WEBSOCKET_RECONNECT_RETRIES = 10
  public static readonly PAYPAL_CLIENT_ID = 'Aab6DpWxprQhNQwg3shtAc3-C9L7XNRjDkPDdFgon-OUStvgytn16lJYVE5PzgDUTWSC52kF86Oon5IZ'
  public static readonly PAYPAL_PREMIUM_PLAN_ID = 'P-7U337233U9062634UMBFUIKQ'
  public static readonly PAYPAL_PLATINUM_PLAN_ID = 'P-6UD57375W06957413MP62U5Y'
  public static readonly MARKET_OPEN_STRING = 'The market is open.'
  public static readonly MARKET_CLOSED_STRING = 'The market is closed.'
  public static readonly MARKET_TIMEZONE = 'America/New_York'
  public static readonly MARKET_LOCALE = 'en-US'
  public static readonly DATASET_INFOS_TABLE_NAME = 'datasetInfos'
  public static readonly WHEEL_SCREENER_BLUE = '#0056b3'
  public static readonly WHEEL_SCREENER_BLUE_DARK_MODE = '#2e93ff'
  public static readonly WHEEL_SCREENER_LIGHT_COLOR = '#ffffff'
  public static readonly WHEEL_SCREENER_DARK_COLOR = '#232323'
  public static readonly MEDIUM_LINK = 'https://medium.com/option-screener'
  public static readonly DISCORD_LINK = 'https://discord.gg/FWusPMhgqd'
  public static readonly TWITTER_LINK = 'https://twitter.com/thewheelscreener'
  public static readonly ANDROID_APP_LINK = 'https://play.google.com/store/apps/details?id=com.optionscreener'
  public static readonly IOS_APP_LINK = 'https://apps.apple.com/us/app/option-screener/id1549790008'
  public static readonly SEARCH_PARAM_KEYS = {
    TICKER: 'ticker'
  }
  public static readonly GOOGLE_TAG = 'G-4D5R54PWXE'
  public static readonly PRODUCT_NAMES = {
    WHEEL_SCREENER: 'The Wheel Screener',
    OPTION_SCREENER: 'Option Screener',
    LEAPS_SCREENER: 'LEAPS Screener'
  }

  // ensure that these line up with the sass variables in _presets.scss
  public static readonly COLORS = {
    LIGHT: '#ffffff',
    DARK: '#232323',
    PRIMARY: '#0056b3',
    GREEN: '#59e59c',
    RED: '#ff6d63',
    GOLD: '#FFD501',
    PURPLE: '#8e44ad',
    PINK: '#f1256e',
    CYAN: '#25ddf1'
  }
}
