import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ICountry } from '../interfaces/ICountry'

export interface CountriesState {
  countries: Array<ICountry>
}

export const countriesInitialState: CountriesState = {
  countries: []
}

export const countriesSlice = createSlice({
  name: 'countries',
  initialState: countriesInitialState,
  reducers: {
    setCountries: (state, action: PayloadAction<Array<ICountry>>) => {
      state.countries = action.payload
    }
  }
})

export const { setCountries } = countriesSlice.actions

export default countriesSlice.reducer
