import { createSlice } from '@reduxjs/toolkit'

export interface FormsState {
  isContactSuccessful: boolean
}

export const formsInitialState: FormsState = {
  isContactSuccessful: false
}

export const formsSlice = createSlice({
  name: 'forms',
  initialState: formsInitialState,
  reducers: {
    setContactSuccessful: (state) => {
      state.isContactSuccessful = true
    }
  }
})

export const { setContactSuccessful } = formsSlice.actions

export default formsSlice.reducer
